// import request from "../request"
import request from "../http"
import {
    AddCartCustomizationReq,
    AddCartReq,
    DeleteCartReq, GetByCodeCustomizationReq, GetByPageCustomizationReq,
    GetCommodityDetailsReq,
    GetCommodityReq,
    PlaceOrderReq,GetExplanReq,
    UpdateNumberReq
} from "@/interface/res";

//加入购物车
export function customizationAddCart (params:GetCommodityReq){
    return request({
        url: '/shop/customization/addCart',
        method: 'post',
        params,
        isToken:false
    })
}
//获取产品列表
export function GetCommodityApi (params:GetCommodityReq){
    return request({
        url: '/shop/commodity/getByPage',
        method: 'get',
        params,
        isToken:false
    })
}
//获取产品列表
export function GetCommodityApiPc (params:GetCommodityReq){
    return request({
        url: '/shop/commodity/getByPage/pc',
        method: 'get',
        params,
        isToken:false
    })
}
//预约产品
export function clickReservation (params:GetCommodityDetailsReq){
    return request({
        url: '/shop/account/reservation/clickReservation',
        method: 'post',
        params,
        isToken:false
    })
}
//获取产品详情
export function GetCommodityDetailsApi (params:GetCommodityDetailsReq){
    return request({
        url: '/shop/commodity/getByDetails',
        method: 'get',
        params,
        isToken:false
    })
}
//获取产品定制详解
export function GetExplain (params:GetExplanReq){
    return request({
        url: '/shop/customization/explain',
        method: 'get',
        params,
        isToken:false
    })
}
//加入购物车
export function AddCartApi (params:AddCartReq){
    return request({
        url: '/shop/shoppingCart/addCart',
        method: 'post',
        params,
        isToken:true
    })
}

//修改购物车数量
export function UpdateNumberApi (params:UpdateNumberReq){
    return request({
        url: '/shop/shoppingCart/updateNumber',
        method: 'post',
        params,
        isToken:true
    })
}

//删除购物车
export function DeleteCartApi (params:DeleteCartReq){
    return request({
        url: '/shop/shoppingCart/deleteCart',
        method: 'post',
        params,
        isToken:true
    })
}

//提交定单
export function calculatePrice (params:PlaceOrderReq){
    return request({
        url: '/shop/order/calculatePrice',
        method: 'post',
        params,
        isToken:true
    })
}
//提交定单
export function PlaceOrderApi (params:PlaceOrderReq){
    return request({
        url: '/shop/order/placeOrder',
        method: 'post',
        params,
        isToken:true
    })
}

//查询高级定制图库列表
export function GetByPageCustomizationApi (params:GetByPageCustomizationReq){
    return request({
        url: '/shop/customization/getByPage',
        method: 'get',
        params,
        isToken:true
    })
}

//查询高级定制图库详情
export function GetByCodeCustomizationApi (params:GetByCodeCustomizationReq){
    return request({
        url: '/shop/customization/getByGalleryId',
        method: 'get',
        params,
        isToken:true
    })
}

//高级定制加入购物车
export function AddCartCustomizationApi (params:AddCartCustomizationReq){
    return request({
        url: '/shop/customization/addCart',
        method: 'post',
        params,
        isToken:true
    })
}

//高级定制发起确认
export function ConfirmCustomizationApi (params:DeleteCartReq){
    return request({
        url: '/shop/customization/confirm',
        method: 'post',
        params,
        isToken:true
    })
}

